@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* AntDesign Modifications : Start */
.ant-message-custom-content {
	display: flex;
	align-items: center;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: transparent !important;
	border-width: 0 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: transparent !important;
	border: none !important;
	border-radius: 0 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 0 !important;
	margin: 0 !important;
	width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	border-radius: 100%;
}
.ant-select-dropdown {
	padding: 10px 0;
	border-radius: 15px;
}
.ant-upload-list-item {
	margin-top: 0 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	cursor: pointer;
}

.ant-picker:hover,
.ant-picker-focused,
.ant-input:hover {
	border-color: #00b388 !important;
	border-right-width: 2px;
}

.ant-picker-focused {
	box-shadow: none;
}

.ant-picker-today-btn {
	color: #00b388 !important;
	font-family: Poppins, sans-serif;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: #00b388 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: none !important;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
	vertical-align: 0.1em;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
	color: #fc8a17;
}

.ant-message-error .anticon {
	color: #fc8a17;
}

.ant-steps-item-process
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	background: #00b388 !important;
}
.ant-steps-item-finish
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	background: #00b388 !important;
}
.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-tail::after {
	background: #00b388 !important;
}

.ant-tooltip-inner {
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.8);
	font-family: "Montserrat", "sans-serif";
}

.ant-popover-inner {
	border-radius: 8px;
}

.ant-modal-content {
	border-radius: 10px !important;
}

.ant-modal-header {
	border-radius: 10px 10px 0 0 !important;
}

.ant-modal-close-x {
	height: 60px;
}

.ant-btn {
	border-radius: 8px !important;
	font-family: "Poppins", Arial, Helvetica, sans-serif !important;
}

.ant-btn-primary[disabled] {
	opacity: 0.5;
}

.ant-btn-primary {
	color: #ffffff !important;
	border-color: #00b388 !important;
	background: #00b388 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
	color: #ffffff !important;
	border-color: #00b389c2 !important;
	background: #00b388c2 !important;
}

.ant-btn:hover,
.ant-btn:focus {
	color: #00b388;
	border-color: #00b388;
}

.ant-modal-close-x {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.ant-btn-sm {
	padding: 0 16px !important;
}

.ant-message-notice-content {
	border-radius: 10px;
	color: #303030;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #00b388;
}

.ant-tabs-tab:hover {
	color: #00b388;
}

.ant-tabs-ink-bar {
	color: #00b388;
}

.ant-tabs-ink-bar {
	background-color: #00b388;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
		.ant-select-customize-input
	)
	.ant-select-selector {
	box-shadow: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #00b38952;
	color: #303030;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
	.ant-select-item-option-state {
	color: #303030;
}

@media only screen and (max-width: 600px) {
	.ant-drawer-content-wrapper {
		width: 300px !important;
	}
}

@media only screen and (min-width: 600px) {
	.ant-steps-item-icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
}

/* AntDesign Modifications : End */

/* For pdf : Start */
.react-pdf__Page__textContent {
	border: 1px solid darkgrey;
	box-shadow: 5px 5px 5px 1px #ccc;
	border-radius: 5px;
	height: 100% !important;
	width: 100% !important;
}

.react-pdf__Page__annotations.annotationLayer {
	padding: 0px;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
	width: 100% !important;
}

.react-pdf__Document:hover .page-controls {
	opacity: 1;
}

.page-controls {
	position: absolute;
	bottom: 5%;
	left: 50%;
	background: white;
	border: 1px solid #e6e6e6;
	opacity: 0;
	transform: translateX(-50%);
	transition: opacity ease-in-out 0.2s;
}

.page-controls span {
	font-family: poppins, sans-serif;
	font-size: 0.8em;
	padding: 0 0.5em;
}
.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
	background-color: #e6e6e6;
}
/* For pdf : End */

.glass_blur {
	color: rgb(255, 255, 255, 0.5);
	background-color: rgb(255, 255, 255, 0.15);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
}

/* Editor JS CSS: Start */
.ce-header {
	font-family: poppins, sans-serif;
	font-weight: 700;
	color: #303030;
}

h1.ce-header {
	font-size: 3rem; /* 48px */
	line-height: 1;
}

h2.ce-header {
	font-size: 2.25rem; /* 36px */
	line-height: 2.5rem; /* 40px */
}

h3.ce-header {
	font-size: 1.875rem; /* 30px */
	line-height: 2.25rem; /* 36px */
}

h4.ce-header {
	font-size: 1.5rem; /* 24px */
	line-height: 2rem; /* 32px */
}

.ce-delimiter {
	height: 2.5rem;
}
.ce-delimiter:before {
	content: "" !important;
}
.cdx-block {
	padding: 0px;
}
.ce-paragraph {
	font-family: raleway, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 400;
	color: #303030;
}
.ce-paragraph a {
	font-family: raleway, sans-serif;
	font-weight: 700;
	color: #43cb83;
	text-decoration: none;
}
.ce-block a {
	font-family: raleway, sans-serif;
	font-weight: 700;
	color: #43cb83;
	text-decoration: none;
}
.cdx-list {
	font-family: raleway, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	font-weight: 400;
	color: #303030;
}
.cdx-nested-list {
	font-family: raleway, sans-serif;
	font-size: 1.125rem;
	line-height: 1.75rem;
	color: #303030;
}
.codex-editor {
	max-width: 100%;
	width: 100%;
	margin: 0 auto 0px auto;
	z-index: auto;
}
.codex-editor__redactor {
	padding-bottom: 150px !important;
}
.codex-editor--narrow .codex-editor__redactor {
	margin-right: 0px;
}
.ce-block__content {
	max-width: 100%;
	margin: 0 auto;
}
.image-tool__image{
	overflow: unset !important;
}
.image-tool--withBackground .image-tool__image {
	background: #ffffff !important;
}
.ce-toolbar {
	z-index: 1;
}
.ce-toolbar__actions {
	right: 0 !important;
	border: 2px solid #e7e5e4;
	background-color: #fafaf9;
	padding: 5px;
	border-radius: 10px;
}
/* Editor JS CSS: Stop */

/* CheckBox Style: Start */

.checkbox {
	position: relative;

	width: 1rem;
	height: 1rem;
	margin-top: 2px;

	cursor: pointer;
	appearance: none;
	outline: 0;
}
.checkbox:before {
	content: "";

	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;

	width: 100%;
	height: 100%;

	border: 2px solid #ced4da;

	transition: all 0.3s ease-in-out;
}

.checkbox:checked:before {
	height: 50%;

	transform: rotate(-45deg);

	border-top-style: none;
	border-right-style: none;
	border-color: #00b388;
}

/* CheckBox Style: End */

/* Scroll Bar Style : Start */

/* width */
.scrollBarHome::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

/* Track */
.scrollBarHome::-webkit-scrollbar-track {
	background: #edf2f7;
}

/* Handle */
.scrollBarHome::-webkit-scrollbar-thumb {
	background: #cbd5e0;
	border-radius: 100vh;
	border: 3px solid #edf2f7;
}

/* Handle on hover */
.scrollBarHome::-webkit-scrollbar-thumb:hover {
	background: #a0aec0;
}

/* Scroll Bar Style : End */

.draggable-main {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	user-select: none;
	font-family: "Raleway", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f0f0f0;
}

.content {
	position: relative;
	width: 100%;
}

.content > div {
	position: absolute;
	width: 100%;
	overflow: visible;
	pointer-events: auto;
	transform-origin: 50% 50% 0px;
}
